
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    link: {
      type: String,
      required: true,
    },
    icon_type: {
      type: String,
      default: "fab",
    },
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
})
export default class SocialLink extends Vue {
  private link!: string;
  private icon_type!: string;
  private icon!: string;
  private name!: string;
}
