<template>
  <div class="social">
    <a :href="link">
      <font-awesome-icon :icon="[icon_type, icon]" />
      {{ name }}
    </a>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    link: {
      type: String,
      required: true,
    },
    icon_type: {
      type: String,
      default: "fab",
    },
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
})
export default class SocialLink extends Vue {
  private link!: string;
  private icon_type!: string;
  private icon!: string;
  private name!: string;
}
</script>

<style lang="scss" scoped>
.social {
  @apply w-60 text-center bg-white bg-opacity-30 rounded text-lg px-5 py-2 m-1;
}
</style>
