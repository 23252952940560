<template>
  <div class="head-alone">
    <div class="logo"><span>TD</span></div>
    <p class="coming-soon">Coming Soon</p>
  </div>
</template>

<style lang="scss" scoped>
.head-alone {
  @apply w-60 text-center bg-white bg-opacity-30 rounded p-5 m-1;
  .logo {
    font-family: Calibri, sans-serif;
    background-color: blueviolet;
    @apply w-12 h-12 m-auto rounded inline-block;
    @apply flex flex-col items-center justify-center;
    span {
      @apply text-center align-middle text-3xl leading-none text-white -mb-1.5;
    }
  }
  .coming-soon {
    @apply text-lg py-2;
  }
}
</style>
