<template>
  <head-alone />
  <social-link
    name="Github"
    icon="github-alt"
    link="https://www.github.com/ThibaudDemay"
  />
  <social-link
    name="LinkedIn"
    icon="linkedin"
    link="https://www.linkedin.com/in/thibauddemay"
  />
  <social-link
    name="500px"
    icon="500px"
    link="https://500px.com/thibaud-demay"
  />
  <social-link
    name="Flickr"
    icon="flickr"
    link="https://www.flickr.com/photos/thibaud-demay/"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HeadAlone from "./components/HeadAlone.vue";
import SocialLink from "./components/SocialLink.vue";

@Options({
  components: {
    HeadAlone,
    SocialLink,
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
body {
  background-image: url("~@/assets/bg.png");
  @apply h-screen;
}

#app {
  @apply h-full flex flex-col items-center justify-center;
}
a {
  @apply no-underline text-black;

  &:hover {
    @apply text-gray-600;
  }
}
</style>
