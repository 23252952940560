import { createApp } from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import "./assets/tailwind.css";

import { FontAwesomeIcon } from "@/plugins/fontawesome.ts";

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(VueGtag, {
    config: {
      id: "G-PPJZ4SYE91",
      params: {
        anonymize_ip: true,
      },
    },
  })
  .mount("#app");
