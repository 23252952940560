
import { Options, Vue } from "vue-class-component";
import HeadAlone from "./components/HeadAlone.vue";
import SocialLink from "./components/SocialLink.vue";

@Options({
  components: {
    HeadAlone,
    SocialLink,
  },
})
export default class App extends Vue {}
